<template>
    <dn-paginator v-bind="$attrs" class="asc-paginator" v-on="$listeners">
        <template v-slot:labelNext>
            <asc-icon icon="chevron-right" class="dn-icon--inline" />
        </template>

        <template v-slot:labelPrevious>
            <asc-icon icon="chevron-left" class="dn-icon--inline" />
        </template>
    </dn-paginator>
</template>

<script>
import DnPaginator from '@digitalnatives/paginator';

import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { DnPaginator, AscIcon }
};
</script>

<style lang="less" src="./paginator.less"></style>
