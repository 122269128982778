<template>
    <div
        class="banner"
        :class="`banner--${version}`"
    >
        <template v-if="version === 'simple-cta'">
            <slot name="title">
                Make this your personal feed
            </slot>
            <div class="banner__button-wrapper" @click.capture="clickHandler('simple-cta')">
                <slot name="cta">
                    <asc-button
                        element="nuxt-link"
                        :to="link.path"
                        :title="link.title"
                        class="dn-button--primary dn-button--inline dn-button--channel"
                        tint="violet"
                    >
                        <template v-slot:default>
                            {{ link.title }}
                        </template>

                        <template v-slot:after>
                            <asc-icon icon="arrow-right" />
                        </template>
                    </asc-button>
                </slot>
            </div>
        </template>

        <template v-if="version === 'big-cta'">
            <h3>
                <slot name="title">
                    Stay up to date
                </slot>
            </h3>
            <p>
                <slot name="body">
                    Get notified about new updates, opportunities or events that match your interests.
                </slot>
            </p>

            <slot name="cta">
                <span @click.capture="clickHandler('big-cta')">
                    <asc-button
                        element="nuxt-link"
                        :to="link.path"
                        :title="link.title"
                        class="dn-button--primary dn-button--inline dn-button--channel"
                        tint="violet"
                    >
                        <template v-slot:default>
                            {{ link.title }}
                        </template>

                        <template v-slot:after>
                            <asc-icon icon="arrow-right" />
                        </template>
                    </asc-button>
                </span>
            </slot>
        </template>
    </div>
</template>

<script>
import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscButton,
        AscIcon
    },

    props: {
        version: {
            type: String,
            default: 'simple-cta'
        },
        link: {
            type: Object,
            default: () => {
                return {
                    title: 'Set up preferences',
                    path: '/sign-up',
                    text: 'Set up your preferences'
                };
            }
        }
    },
    methods: {
        clickHandler(version) {
            this.$matomo.trackEvent('join', 'via-updates-banner', version);
        }
    }
};
</script>

<style lang="less" src="./banner.less" />
