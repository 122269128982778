<template>
    <transition name="fade">
        <section v-if="$fetchState.pending" class="feed">
            <a id="feedTop" name="feedTop" />
            <card-placeholder v-for="i in new Array(itemsPerPage)" :key="i" />
        </section>

        <section v-else-if="updates && updates.length" class="feed">
            <a id="feedTop" name="feedTop" />

            <asc-card v-for="(update, index) in updates" :key="index" :update="update">
                <asc-banner
                    v-if="index === 3 && !$auth.loggedIn && paginatorInfo.currentPage === 1 && userIds === null"
                    slot="banner"
                />
            </asc-card>

            <asc-banner
                v-if="showBigCta && !$auth.loggedIn"
                version="big-cta"
            />

            <div class="feed__paginator">
                <asc-paginator v-if="showPagination" :total-pages="paginatorInfo.lastPage" :current-page.sync="currentPageNum" />
            </div>
        </section>

        <section v-else class="feed">
            <p>Sorry! There's no updates to show here right now.</p>
        </section>
    </transition>
</template>

<script>
import { formatISO, parseISO, endOfMonth } from 'date-fns';

import AscPaginator from '~/patterns/molecules/paginator/paginator.vue';
import Card from '~/patterns/molecules/card/card.vue';
import CardPlaceholder from '~/patterns/molecules/card/card-placeholder.vue';
import Banner from '~/patterns/molecules/banner/banner.vue';

import updateIndexQuery from '~/graphql/queries/update/index.graphql';

export default {
    components: {
        'asc-card': Card,
        'asc-banner': Banner,
        CardPlaceholder,
        AscPaginator
    },

    props: {
        showPagination: {
            type: Boolean,
            default: false
        },
        itemsPerPage: {
            type: Number,
            default: 20
        },
        updateTypes: {
            type: Array,
            required: false,
            default: null
        },
        topics: {
            type: Array,
            required: false,
            default: null
        },
        userIds: {
            type: Array,
            required: false,
            default: null
        },
        showBigCta: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            currentPageNum: 1,
            updates: [],
            paginatorInfo: {
                lastPage: 1,
                total: 0
            }
        };
    },

    async fetch() {
        const variables = { page: this.currentPageNum, amount: this.itemsPerPage };
        variables.orderBy = [
            { column: 'IS_HIGHLIGHTED', order: 'DESC' },
            { column: 'CREATED_AT', order: 'DESC' }
        ];

        if (this.updateTypes) {
            variables.types = this.updateTypes;

            // If we're exclusively listing events, add some additonal event conditions.
            if (this.updateTypes.indexOf('event') === 0) {
                const startOfTheDay = new Date();
                startOfTheDay.setUTCHours(0, 0, 0, 0);

                variables.orderBy = [{ column: 'EVENT_STARTDATE', order: 'ASC' }];
                variables.eventDateRange = { from: formatISO(startOfTheDay) };
            }
        }

        if (this.$route.query.eventPeriod) {
            variables.eventDateRange = {
                from: this.$route.query.eventPeriod,
                to: formatISO(endOfMonth(parseISO(this.$route.query.eventPeriod)))
            };
        }

        if (this.$route.query.eventType) {
            variables.eventType = this.$route.query.eventType;
        }

        if (this.topics) {
            variables.topics = this.topics;
        }

        if (this.userIds) {
            variables.userIds = this.userIds;
        }

        const results = await this.$gql.executeQuery(updateIndexQuery, variables);

        if (results.data) {
            this.updates = results.data.updates.data;
            this.paginatorInfo = results.data.updates.paginatorInfo;
        }
    },

    watch: {
        '$route.query.page': {
            immediate: true,
            handler(newPageNumber) {
                if (newPageNumber) {
                    this.currentPageNum = Number(newPageNumber);
                }
            }
        },

        topics() {
            this.$fetch();
        },

        updateTypes() {
            this.$fetch();
        },

        itemsPerPage() {
            this.$fetch();
        },

        currentPageNum() {
            if (typeof document !== 'undefined') {
                document.getElementById('feedTop').scrollIntoView({ behavior: 'smooth' });
            }
            this.$fetch();
        }
    }
};
</script>

<style lang="less">
.feed {
    @media @q-lg-min {
        margin-top: var(--spacing-lg);
    }
}

.feed__paginator {
    margin: var(--spacing-xl) 0 var(--spacing-lg) 0;

    @media @q-sm-min {
        margin-top: var(--spacing-2xl);
    }
}
</style>
